<script>
import playerMatchStatsComponent from './playerMatchStatsComponent.vue'
import { httpGet } from '../assets/js/utils.js'

export default {
    props: ['playerId', 'teamPlayers', 'limit', 'team'],
    components: {
        playerMatchStatsComponent
    },
    watch: {
        limit: function() {
            this.getData()
        },
        playerId: function() {
            this.getData()
        },
		currentPlayerId() {
            this.scrollToSelectedPlayer();
            this.getData();
        }
    },
    data() {
        return {
			currentPlayerId: this.playerId,
            playerInfo: null,
            playerStats: null,
            isLoadingInfoPlayer: false,
            isLoadingPlayerStats: false
        };
    },
	computed: {
        currentIndex() {
            return this.teamPlayers.findIndex(player => player.player.id === this.currentPlayerId);
        },
        // nextPlayer() {
        //     return this.currentIndex < this.teamPlayers.length - 1 
        //         ? this.teamPlayers[this.currentIndex + 1].player 
        //         : null;
        // },
        // previousPlayer() {
        //     return this.currentIndex > 0 
        //         ? this.teamPlayers[this.currentIndex - 1].player 
        //         : null;
        // },
		totalMatchesPlayed() {
			// Count entries in the fixtures array with minutes > 0
			return this.playerStats.fixtures.filter((match) => match.minutes > 0).length;
		},
    },
    methods: {
        async fetchPlayerInfoData() {
            return httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/playerInfo/${this.currentPlayerId}`);
        },
        async fetchPlayerStatsData() {
            return httpGet(`${process.env.VUE_APP_BACKEND_URL}/api/bestbets/playerStats/${this.currentPlayerId}?limit=${this.limit}`);
        },
        async getData() {
            try {
                this.isLoadingInfoPlayer = true;
                this.playerInfo = await this.fetchPlayerInfoData();
				// console.log(this.playerInfo);
                this.isLoadingInfoPlayer = false;

                this.isLoadingPlayerStats = true;
                this.playerStats = await this.fetchPlayerStatsData();
				// console.log(this.playerStats);
                this.isLoadingPlayerStats = false;
            } catch (error) {
                console.error('Error fetching player data:', error);
                this.isLoadingInfoPlayer = false;
                this.isLoadingPlayerStats = false;
            }
        },
		selectPlayer(playerId) {
            this.currentPlayerId = playerId;
        },
        scrollToSelectedPlayer() {
            this.$nextTick(() => {
                const container = this.$refs.navigationButtons;
                const activeButton = container.querySelector('.active');
                if (activeButton) {
                    const containerWidth = container.clientWidth;
                    const buttonOffset = activeButton.offsetLeft + activeButton.clientWidth / 2;
                    container.scrollLeft = buttonOffset - containerWidth / 2;
                }
            });
        }
    },
    created() {
        this.getData();
    },
	mounted() {
        this.scrollToSelectedPlayer();
    }
};
</script>

<template>
    <div class="player-info-pop">
        <div class="player-info">
            <div class="player-info-header">
                <div class="player-name">
                    <h1>{{ playerInfo?.name }}</h1>
					<div class="player-position">
                        <div class="position-circle">
                            <p>{{ playerInfo?.shortPosition }}</p>
                        </div>
                        <h3>{{ playerInfo?.position }}</h3>
                    </div>
                </div>
                <div class="player-demographics">
                    <h3>{{ team }}</h3>
					<p>{{ playerInfo?.age }} years</p>
                </div>
            </div>
            <div class="player-stats" v-if="playerStats">
				<!-- <p class="title">Averages from last 10 matches</p> -->
				<!-- <p>Last 10 matches</p> -->
                <!-- <div class="greyheader">
                    <div class="greyheader-value">
                        <p>Minutes</p>
                    </div>
                    <div class="greyheader-value" id="grey-shots">
                        <p>Shots</p>
                    </div>
                    <div class="greyheader-value" id="grey-ot">
                        <p>On Target</p>
                    </div>
                    <div class="greyheader-value" id="grey-assists">
                        <p>Assists</p>
                    </div>
                    <div class="greyheader-value" id="grey-goals">
                        <p>Goals</p>
                    </div>
                </div> -->
				<div class="greyheader">
                    <div class="greyheader-player">
                        <p>Averages</p>
                    </div>
                    <div class="greyheader-value" id="grey-minutes">
                        <p>Min.</p>
                    </div>
                    <div class="greyheader-value" id="grey-shots">
                        <p>S</p>
                    </div>
                    <div class="greyheader-value" id="grey-target">
                        <p>OT</p>
                    </div>
                    <div class="greyheader-value" id="grey-assists">
                        <p>A</p>
                    </div>
                    <div class="greyheader-value" id="grey-goals">
                        <p>G</p>
                    </div>
                </div>
                <div class="flex">
					<div class="avg-played"><p>Played {{ totalMatchesPlayed }} matches</p></div>
                    <div class="avg-box player-minutes-value">
                        <p>{{ playerStats?.averageStats?.minutes }}</p>
                    </div>
                    <div class="avg-box shots-value" v-bind:class="`player-shots-value-${playerStats?.averageStats?.shots * 10}`">
                        <p>{{ playerStats?.averageStats?.shots }}</p>
                    </div>
                    <div class="avg-box ot-shots-value" v-bind:class="`player-ot-shots-value-${playerStats?.averageStats?.onTarget * 10}`">
                        <p>{{ playerStats?.averageStats?.onTarget }}</p>
                    </div>
                    <div class="avg-box assist-value" v-bind:class="`player-assists-value-${playerStats?.averageStats?.assists * 10}`">
                        <p>{{ playerStats?.averageStats?.assists }}</p>
                    </div>
                    <div class="avg-box goals-value" v-bind:class="`player-goals-value-${playerStats?.averageStats?.goals * 10}`">
                        <p>{{ playerStats?.averageStats?.goals }}</p>
                    </div>
                </div>
				<!-- <p>Last 10 matches</p> -->
            </div>
            <div class="player-match-list" v-if="playerStats">
                <div class="greyheader">
                    <div class="greyheader-player">
                        <p>Last 10 matches</p>
                    </div>
                    <div class="greyheader-value" id="grey-minutes">
                        <p>Min.</p>
                    </div>
                    <div class="greyheader-value" id="grey-shots">
                        <p>S</p>
                    </div>
                    <div class="greyheader-value" id="grey-target">
                        <p>OT</p>
                    </div>
                    <div class="greyheader-value" id="grey-assists">
                        <p>A</p>
                    </div>
                    <div class="greyheader-value" id="grey-goals">
                        <p>G</p>
                    </div>
                </div>
                <div class="player-match-list-items" v-if="!isLoadingPlayerStats">
                    <playerMatchStatsComponent :playerStats="playerStats?.fixtures"></playerMatchStatsComponent>
                </div>
				<div class="skeleton-player-match-list" v-if="isLoadingPlayerStats">
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>	
					<div class="player-match">
						<div class="match">
							<div class="match-info">
								<div class="match-result skeleton skeleton-player">
								</div>
							</div>
						</div>
						<div class="player-match-box minutes value skeleton">
						</div>
						<div class="player-match-box shots skeleton">
						</div>
						<div class="player-match-box ot skeleton">
						</div>
						<div class="player-match-box assist skeleton">
						</div>
						<div class="player-match-box goals skeleton">
						</div>
					</div>
				</div>
            </div>
        </div>
        <div class="close-bar">
			<!-- <div class="navigation-buttons">
                <button class="button-secondary" @click="goToPreviousPlayer" :disabled="!previousPlayer">
					<div class="player">
						<div class="position-circle" v-if="previousPlayer">
							<p>{{ previousPlayer?.shortPosition }}</p>
						</div>
						<p>{{ previousPlayer?.name || 'N/A' }}</p>
					</div>
				</button>
				<button class="button-secondary" @click="goToNextPlayer" :disabled="!nextPlayer">
					<div class="player">
						<div class="position-circle" v-if="nextPlayer">
							<p>{{ nextPlayer?.shortPosition }}</p>
						</div>
						<p>{{ nextPlayer?.name || 'N/A' }}</p>
					</div>
				</button>
			</div> -->
			<div class="navigation-buttons-wrapper">
                <div class="navigation-buttons" ref="navigationButtons">
                    <button
                        v-for="(player) in teamPlayers"
                        :key="player.player.id"
						class="button-secondary"
                        :class="{ active: player.player.id === currentPlayerId }"
                        @click="selectPlayer(player.player.id)"
                    >
                        <div class="player">
                            <div class="position-circle">
                                <p>{{ player.player.shortPosition }}</p>
                            </div>
                            <p>{{ player.player.name }}</p>
                        </div>
                    </button>
                </div>
            </div>
            <div class="close-btn" @click="$emit('closePlayer')">
                <p>Close Player Info</p>
                <img src="./../assets/img/close-white.png" />
            </div>
        </div>
    </div>
</template>

<style scoped src="../assets/css/style.css">
    .crd-tab > div:not(.spinner) {
        display: none;
    }
    /* Show the spinner when isLoading is true */
    .crd-tab .spinner {
        display: block;
    }
</style>
