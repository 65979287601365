<script>
	export default {
			props: ['completeStandings', 'selected_tab', 'teamsPlaying']
	};
</script>


<template>
	<div class="crd-tab" id="crd-tab-stand" v-if="selected_tab == 'completeStanding'">
		<div class="stnd">
			<div class="stnd-row stnd-title greyheader">
				<div class="stnd-name">
					<p>Standings</p>
				</div>
				<div class="stnd-1 stnd-pts">
					<p>Pt</p>
				</div>
				<div class="stnd-1">
					<p>P</p>
				</div>
				<div class="stnd-form">
					<p>W</p>
					<p class="score-divider">-</p>
					<p>D</p>
					<p class="score-divider">-</p>
					<p>L</p>
				</div>
				<div class="stnd-goals">
					<p>GF</p>
					<p class="score-divider">:</p>
					<p>GA</p>
				</div>
			</div>
			<div class="complete-stnd">
				<div class="stnd-row stnd-data" v-for="(standing) in completeStandings" :key="standing.rank" :class="{ 'playing-team': teamsPlaying && teamsPlaying.includes(standing.name) }">
					<div class="stnd-rank">
						<p>{{ standing.rank }}</p>
					</div>
					<div class="stnd-name">
						<p>{{ standing.name }}</p>
					</div>
					<div class="stnd-1 stnd-pts">
						<p>{{ standing.points }}</p>
					</div>
					<div class="stnd-1">
						<p>{{ standing.played }}</p>
					</div>
					<div class="stnd-form">
						<p>{{ standing.win }}</p>
						<p class="score-divider">-</p>
						<p>{{ standing.draw }}</p>
						<p class="score-divider">-</p>
						<p>{{ standing.lose }}</p>
					</div>
					<div class="stnd-goals">
						<p>{{ standing.goalsFor }}</p>
						<p class="score-divider">:</p>
						<p>{{ standing.goalsAgainst }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
</style>

